@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeue/HelveticaNeue-01.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeue/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./HelveticaNeue/HelveticaNeue-CondensedBold-05.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2rem !important;
  }
} ;
